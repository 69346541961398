import { translate } from "@/i18n";
import { classNames } from "@/Utils/theme";
import { usePage } from "@inertiajs/react";
import { useEffect } from "react";

interface ThemeHookProps {}

export default function ThemeHook({ ...props }): JSX.Element {
    const { branding } = usePage().props;

    useEffect(() => {
        if (branding && branding.primary_color) {
            changeThemeColor(branding.primary_color);
        }
    }, [branding]);

    const changeThemeColor = (rgbString: string) => {
        // extract values from rgb string
        const values = rgbString.match(/\d+/g)?.join(", ");

        if (!values) return;
        document.documentElement.style.setProperty("--color-primary", values);
    };

    return <></>;
}

const $container = "";
